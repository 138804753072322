var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "table-cnt" },
    [
      _c(
        "el-table",
        {
          attrs: {
            data: _vm.tableData,
            height: "100%",
            border: true,
            "header-cell-style": { color: "#333333", background: "#EFF6FF" },
          },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "business", align: "center", label: "集配中心" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$empty.empty(scope.row.business.name)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "order_no", align: "center", label: "订单号" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$empty.empty(scope.row.order.order_no)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "supplier_order_no",
              align: "center",
              label: "供货单编号",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$empty.empty(
                            scope.row.supplier_order.supplier_order_no
                          )
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "title", align: "center", label: "供货商名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$empty.empty(scope.row.supplier.title)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "title", align: "center", label: "业务名称" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$empty.empty(scope.row.buyer.fullname)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "title", align: "center", label: "商品名称(货号)" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          `${scope.row.product.title}(${scope.row.product.sku_code})`
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              align: "supplier_order",
              label: "供货单创建时间",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$empty.empty(scope.row.supplier_order.create_time)
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              align: "supplier_order",
              label: "订单创建时间",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$empty.empty(scope.row.order.create_time)) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              prop: "product_amt",
              align: "center",
              label: "商品总金额",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "aftersale_amt",
              align: "center",
              label: "售后总金额",
            },
          }),
          _c("el-table-column", {
            attrs: { prop: "qc_out_amt", align: "center", label: "缺货总金额" },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "financial_fee",
              align: "center",
              label: "金融手续费(0.4%)",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sell_settlement_fee",
              align: "center",
              label: "应收服务费",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sell_settlement_fee_real",
              align: "center",
              label: "实际服务费",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "sell_settlement_fee_diff",
              align: "center",
              label: "差额",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "settlement_amt",
              align: "center",
              label: "结算金额",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }