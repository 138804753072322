<template>
  <div class="table-cnt">
    <el-table
      :data="tableData"
      height="100%"
      :border="true"
      :header-cell-style="{ color: '#333333', background: '#EFF6FF' }"
    >
      <el-table-column prop="business" align="center" label="集配中心">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.business.name) }}
        </template>
      </el-table-column>
      <el-table-column prop="order_no" align="center" label="订单号">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.order.order_no) }}
        </template>
      </el-table-column>
      <el-table-column
        prop="supplier_order_no"
        align="center"
        label="供货单编号"
      >
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.supplier_order.supplier_order_no) }}
        </template>
      </el-table-column>
      <el-table-column prop="title" align="center" label="供货商名称">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.supplier.title) }}
        </template>
      </el-table-column>
      <el-table-column prop="title" align="center" label="业务名称">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.buyer.fullname) }}
        </template>
      </el-table-column>
      <el-table-column prop="title" align="center" label="商品名称(货号)">
        <template slot-scope="scope">
          {{ `${scope.row.product.title}(${scope.row.product.sku_code})` }}
        </template>
      </el-table-column>
      <el-table-column
        prop="name"
        align="supplier_order"
        label="供货单创建时间"
      >
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.supplier_order.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="name" align="supplier_order" label="订单创建时间">
        <template slot-scope="scope">
          {{ $empty.empty(scope.row.order.create_time) }}
        </template>
      </el-table-column>
      <el-table-column prop="product_amt" align="center" label="商品总金额">
      </el-table-column>
      <el-table-column prop="aftersale_amt" align="center" label="售后总金额">
      </el-table-column>
      <el-table-column prop="qc_out_amt" align="center" label="缺货总金额">
      </el-table-column>
      <el-table-column
        prop="financial_fee"
        align="center"
        label="金融手续费(0.4%)"
      >
      </el-table-column>
      <el-table-column
        prop="sell_settlement_fee"
        align="center"
        label="应收服务费"
      >
      </el-table-column>
      <el-table-column
        prop="sell_settlement_fee_real"
        align="center"
        label="实际服务费"
      >
      </el-table-column>
      <el-table-column
        prop="sell_settlement_fee_diff"
        align="center"
        label="差额"
      ></el-table-column>
      <el-table-column prop="settlement_amt" align="center" label="结算金额">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  name: "TableList",
  props: ["tableData"],
  components: {},

  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.table-cnt {
  height: 100%;

  .goods-image {
    width: 50px;
    height: 50px;
  }
}
</style>
